import { StoryblokComponent } from '@/types/types-storyblok';
import { FunctionComponent } from 'react';
import { StoryblokPageComponent } from './page';
import { StoryblokHomeHeroBanner } from './homeHeroBanner';
import { StoryblokLogosStripComponent } from './logosStrip';
import { SectionWithBulletpointsAndImage } from './sectionWithBulletpointsAndImage';
import { StoryblokTestimonialStrip } from './testimonialStrip';
import { StoryblokSmallCardsSection } from './smallCardsSection';
import { StoryblokCardsCarousel } from './cardsCarousel';
import { StoryblokSmallCardWithImage } from './smallCardsSection/smallCard';
import { StoryblokServiceCard } from './smallCardsSection/serviceCard';
import { StoryblokHomeHeroBannerSearchForm } from './homeHeroBanner/searchForm';
import { StoryblokHomeHeroBannerRichTextWithCta } from './homeHeroBanner/richTextWithCta';
import { StoryblokHomeHeroBannerDavinciInfoForm } from './homeHeroBanner/davinciInfoForm';
import { StoryblokPost } from './post';
import { StoryblokSection } from './section';
import { StoryblokStory } from 'storyblok-generate-ts';
import { StoryblokBookingCta } from './bookingCta';
import { StoryblokBookingCtaSmall } from './bookingCtaSmall';
import { StoryblokRichtextTitle } from './richtextTitle';
import { StoryblokHtmlEmbed } from './htmlEmbed';
import { StoryblokHubspotForm } from './hubspotForm';
import { StoryblokInlineCTA as StoryblokInlineCta } from './inlineCta';
import { StoryblokVerticalSpace } from './verticalSpace';
import { StoryblokAdBanner } from './adBanner';
import { StoryblokCompareTable } from './compareTable';
import { StoryblokCtaBanner } from './ctaBanner';
import { StoryblokNewsletterBanner } from './newsletterBanner';
import { StoryblokScriptEmbed } from './scriptEmbed';
import { StoryblokPostsBanner } from './postsBanner';
import { StoryblokLogosStripBig } from './logosStripBig';
import { StoryblokImage } from './image';
import { StoryblokEmptyHeader } from './emptyHeader';
import { StoryblokCookiebotCookiesDeclaration } from './cookiebotCookiesDeclaration';

const voidComponent = () => null;

export type StoryblokReactComponent<T extends StoryblokComponent> = FunctionComponent<StoryblokReactComponentProps<T>>;
export type StoryblokReactComponentProps<T extends StoryblokComponent> = { component: T; story: StoryblokStory<StoryblokComponent> };

export const componentsMap: {
  [SBComponent in StoryblokComponent as SBComponent['component']]: StoryblokReactComponent<SBComponent>;
} = {
  homeHeroBanner: StoryblokHomeHeroBanner,
  page: StoryblokPageComponent,
  logosStrip: StoryblokLogosStripComponent,
  sectionWithBulletpointsAndImage: SectionWithBulletpointsAndImage,
  testimonialStrip: StoryblokTestimonialStrip,
  smallCardsSection: StoryblokSmallCardsSection,
  smallCardWithImage: StoryblokSmallCardWithImage,
  serviceCard: StoryblokServiceCard,
  cardsCarousel: StoryblokCardsCarousel,
  homeHeroBannerSearchForm: StoryblokHomeHeroBannerSearchForm,
  homeHeroBannerDavinciInfoForm: StoryblokHomeHeroBannerDavinciInfoForm,
  homeHeroBannerRichtextWithCta: StoryblokHomeHeroBannerRichTextWithCta,
  post: StoryblokPost,
  section: StoryblokSection,
  bookingCta: StoryblokBookingCta,
  bookingCtaSmall: StoryblokBookingCtaSmall,
  richtextTitle: StoryblokRichtextTitle,
  htmlEmbed: StoryblokHtmlEmbed,
  hubspotForm: StoryblokHubspotForm,
  inlineCallToAction: StoryblokInlineCta,
  verticalSpace: StoryblokVerticalSpace,
  adBanner: StoryblokAdBanner,
  compareTable: StoryblokCompareTable,
  ctaBanner: StoryblokCtaBanner,
  newsletterBanner: StoryblokNewsletterBanner,
  scriptEmbed: StoryblokScriptEmbed,
  postsBanner: StoryblokPostsBanner,
  logosStripBig: StoryblokLogosStripBig,
  image: StoryblokImage,
  emptyHeader: StoryblokEmptyHeader,
  CookiebotCookiesDeclaration: StoryblokCookiebotCookiesDeclaration,
  cardsSectionCard: voidComponent,
  standardCardsCarousel: voidComponent,
  dynamicText: voidComponent,
  linkCallToAction: voidComponent,
  plansCarousel: voidComponent,
  plansCarouselPlan: voidComponent,
  reviewsCarousel: voidComponent,
  testimonial: voidComponent,
  seoData: voidComponent,
  author: voidComponent,
  category: voidComponent,
  feature: voidComponent,
  Footer: voidComponent,
  grid: voidComponent,
  Header: voidComponent,
  LinkGroup: voidComponent,
  Social: voidComponent,
  teaser: voidComponent,
  bulletPoint: voidComponent,
  Link: voidComponent,
  cardsWithBackgroundCarouselCard: voidComponent,
  cardsWithBackgroundCarousel: voidComponent,
  appStoreButton: voidComponent,
  blogHomepage: voidComponent,
  peopleCarousel: voidComponent,
  peopleCarouselCard: voidComponent,
  howWorkText: voidComponent,
  medicalVisitList: voidComponent,
  relatedArticles: voidComponent,
  bookingCardListing: voidComponent,
  medicalVisitCard: voidComponent,
  visitMainInformation: voidComponent,
  visitMainInformationCard: voidComponent,
  contentSectionCarousel: voidComponent,
  howWorkCards: voidComponent,
  howWorkCardsCard: voidComponent,
  prenotaVisita: voidComponent,
  prenotaVisitaCity: voidComponent,
  pagePrenotaVisita: voidComponent,
  imageWithLink: voidComponent,
  logosStripBigRow: voidComponent,
  homeHeroBannerSelect: voidComponent,
  logoStripBigRowItem: voidComponent,
};
